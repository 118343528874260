import Swiper, {Navigation, Pagination, Thumbs, FreeMode, Grid} from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const mainSwiperThumb = new Swiper(".main-swiper__thumb", {
    modules: [FreeMode, Grid],
    loop: true,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,

    breakpoints: {
        320: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        960: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
});

const mainSwiper = new Swiper('.main-swiper', {
    modules: [Thumbs],
    loop: true,
    thumbs: {
        swiper: mainSwiperThumb,
    },

});

const indexAboutSwiper = new Swiper('.about-swiper', {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    loop: true,
    pagination: {
        el: ".about-swiper__pagination",
        type: "fraction",
    },
    navigation: {
        nextEl: ".about-swiper__next",
        prevEl: ".about-swiper__prev",
    },
});

const fullGalleryThumb = new Swiper(".gallery-swiper__thumb", {
    modules: [FreeMode],
    loop: true,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,

    breakpoints: {
        320: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        960: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
});

const fullGallerySwiper = new Swiper('.gallery-swiper', {
    modules: [Navigation, Thumbs],
    loop: true,
    navigation: {
        nextEl: ".gallery-swiper__next",
        prevEl: ".gallery-swiper__prev",
    },
    thumbs: {
        swiper: fullGalleryThumb,
    },
});

const projectsSwiper = new Swiper('.projects-swiper', {
    modules: [Navigation],
    spaceBetween: 30,
    slidesPerView: 4,
    loop: true,

    navigation: {
        nextEl: ".projects-swiper__next",
        prevEl: ".projects-swiper__prev",
    },

    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        960: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
});


const awardsSwiper = new Swiper('.awards-swiper', {
    modules: [Navigation],
    spaceBetween: 30,
    slidesPerView: 4,
    loop: true,

    navigation: {
        nextEl: ".awards-swiper__next",
        prevEl: ".awards-swiper__prev",
    },

    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        960: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
});


const certificatesSwiper = new Swiper('.certificates-swiper', {
    modules: [Navigation],
    spaceBetween: 30,
    slidesPerView: 4,
    loop: true,

    navigation: {
        nextEl: ".certificates-swiper__next",
        prevEl: ".certificates-swiper__prev",
    },

    breakpoints: {
        320: {
            slidesPerView: 1.5,
            spaceBetween: 16,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        960: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
});

const chiefsMobileSwiper = new Swiper('.chiefs-swiper', {
    modules: [Navigation],
    spaceBetween: 16,
    slidesPerView: 1.5,
    loop: true,
    navigation: {
        nextEl: ".chiefs-swiper__next",
        prevEl: ".chiefs-swiper__prev",
    },
});
